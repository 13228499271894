<template>
  <static-template>
    <h1>古物営業法に基づく表記</h1>
    <br /><br />

    <ul>
      <li><strong>事業者: </strong>NEO ONE ART株式会社（NEO ONE ART INC.）</li> 
      <li><strong>古物商許可番号: </strong>東京都公安委員会 第301122219123号</li>
    </ul>
  </static-template>
</template>

<script>
export default {
  name: 'NotationOldPage',
};
</script>
